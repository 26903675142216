.details {
  &--navigation {
    background-color: $jmb-darkblue;
    
    &--content {
      @include page-content-layout();
      @include page-content-h-padding();
      @include v-padding($spacing-small, $spacing-small, $spacing-small);
      display: flex;
    }

    a {
      @include font-navigation-object();
      display: flex;
      align-items: center;
    }
    img {
      width: auto;
      height: to-rem(18px);
      margin-right: to-rem(7px);
      margin-top: to-rem(-3px);
    }
    
    &--group2 {
      margin-left: auto;
      display: inline-flex;
    }
    &--next {
      margin-left: 1.3rem;
      @media #{$tablet-screen-up} {
        margin-left: 3.1rem;
      }
      img {
        margin-right: 0;
        margin-left: to-rem(7px);
      }
    }

    &--label--mobile-hidden {
      display: none;
      @media #{$tablet-screen-up} {
        display: inline;
      }
    }
  }

  &--content--wrapper {
    @include page-content-layout();
    @include page-content-h-padding();
    @include page-content-v-padding-big();
  }
  
  &--header {
    @include margin-bottom(2rem, $spacing-medium, $spacing-medium);

    h1 {
      margin-bottom: 0;
    }
    p {
      margin-top: $spacing-tiny;
      a {
        @include font-h2-subhead-medium();
        cursor: pointer;
        text-decoration: none;
        transition: color $base-duration $base-timing;

        &:active,
        &:hover {
          text-decoration: underline;
          color: $jmb-berry;
        }
      }
    }
    &--line2 {
      @include font-h2-subhead-medium();
    }
  }

  &--content {
    &.grid {
      @include grid();
      grid-template-columns: 1fr;
      grid-row-gap: $spacing-medium;

      @media #{$tablet-screen-up} {
        grid-row-gap: 0;
        grid-template-columns: 5fr 1fr 6fr;
      }
        
      .details--content--right {
        @media #{$tablet-screen-up} {
          grid-column-start: 3;
          grid-column-end: 4;
        }
      }
    }
  }

  &--big-image {
    margin-bottom: to-rem(20.8px);

    &--container {
      text-align: center;
    }

    &--link {
      @include font-link();
      display: inline-flex;
      align-items: center;
      img {
        width: to-rem(24px);
        height: auto;
        margin-right: to-rem(15px);
      }
    }
  }

  &--slider {
    margin-top: to-rem(40px);
  }

  &--dfg-viewer {
    margin-top: to-rem(40px);

    &--title {
      @include font-copy-bold();
    }

    &--link {
      display: inline-flex;
      align-items: center;

      &--icon {
        width: to-rem(12.8px);
        height: auto;
        margin-right: 0.4rem;
        margin-top: -3px;
      }
    }
  }

  &--search-all-related-btn {
    margin-top: to-rem(40px);
  }
}