.attr {
  &--label,
  &--value,
  &--list {
    @include font-body();
    display: inline;
  }

  &--list-item {
    display: inline;
  }


  &-medium {
    @include font-body-medium();
  }
  
  &-default {
    margin-bottom: .75em;
  }

  &-details {
    border-top: 1px solid $jmb-gray-divider;
    padding: $spacing-tiny 0;
    @media #{$tablet-screen-up} {
      display: flex;
    }
    
    label {
      padding-right: 0.6rem;
      @media #{$tablet-screen-up} {
        min-width: to-rem(260px);
        margin-bottom: 0;
      }
    }
  }

}