.facetpanel {
  grid-area: facetpanel;

  &--title {
    @include font-copy-large-medium();
  }

  &--toggler {
    display: inline-flex;
    align-items: center;
    margin-bottom: $spacing-small;
    padding: 0.9rem;

    @include jmb-button-with-icon();
  
    @media #{$tablet-screen-up} {
      display: none
    }
  
    img {
      margin-right: 0.6rem;
      margin-top: -0.15rem;
      height: 1.1rem;
      width: auto;
    }
  }
}
