.teaser {
  &--list {
    // @include masonry-homepage-teasers();
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;

    @media #{$mobile-wide-screen-up} {
      margin-left: -1%;
      margin-right: -1%;
    }
  }

  &--item {
    margin-bottom: to-rem(80px);
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
    @media #{$mobile-wide-screen-up} {
      width: 50%;
    }
    @media #{$tablet-screen-up} {
      width: 33.333%;
    }
  }

  &--type-text {
    border: 1px solid $black;
    padding: to-rem(16px);
  }

  &--bottom-link {
    margin-top: to-rem(16px);
  }

  &--figure {
    margin-bottom: to-rem(16px); // TODO
    @media #{$tablet-screen-up} {
      margin-bottom: to-rem(20.8px);
    }
  }

  &--link {
    display: block;
    cursor: pointer;
    
    &:active,
    &:hover {
      text-decoration: underline;

      p {
        text-decoration: underline;
      }
    }
  }

  &--title {
    @include font-h-teaser-black();
    .teaser--type-image & {
      margin-bottom: 0;
    }
  }

  &--description {
    @include font-h-teaser-medium();
    display: inline;

    a {
      font-weight: $fw-bold;
      &:hover {
        border-bottom: 1px solid currentColor;
      }
    }
  }

  &--bottom-link {
    display: inline-flex;
    align-items: flex-start;
    margin-top: 1.2rem;

    @media #{$tablet-screen-up} {
      margin-top: 1.3rem;
    }

    img {
      max-height: to-rem(17.6px);
      width: auto;
      display: inline-block;
      margin-left: to-rem(6px);
    }
  }

  &--table-col {
    &--thumbnail {
      max-width: to-rem(150px);
    }
  }

  &--form {
    &--image-fieldset {
      display: flex;
      flex-direction: column;

      &--image {
        margin-bottom: $base-spacing;
        img {
          max-width: 150px;
        }
      }

      @media #{$tablet-screen-up} {
        flex-direction: row;
        &--fields {
          &:nth-child(2) {
            margin-left: to-rem(16px);
            flex: 1;
          }
        }
      }
    }
  }
}