.page-header {
  background-color: $jmb-blue;
  
  &--content {
    @include page-content-layout();
    @include page-content-h-padding();
    @include page-header-v-padding();

    @include grid();
    justify-content: space-between;
    grid-template-columns: auto;
    grid-template-areas:
      "branding burger-menu";
    align-items: center;

    @media #{$tablet-screen-up} {
      grid-template-columns: 4fr 8fr;
      grid-template-areas:
        "branding main-nav";
    }
  }

  &--logo {
    grid-area: branding;
    @media #{$tablet-screen-up} {
      align-self: stretch;
    }

    img {
      width: to-rem(160px);
      height: auto;

      @media #{$tablet-screen-up} {
        width: auto;
        height: 100%;
      }
    }
  }

  &--navigation {
    grid-area: main-nav;
    display: none;

    @media #{$tablet-screen-up} {
      display: block;
      justify-self: end;
      margin-top: to-rem(25.6px);
    }
  }

  &--mobile-menu-button {
    grid-area: burger-menu;
    @media #{$tablet-screen-up} {
      display: none;
    }
  }

  &--admin-nav {
    display: none;
    @media #{$tablet-screen-up} {
      display: block;
    }
  }
}