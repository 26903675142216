.keywordpage {
  &--searched-query {
    font-style: italic;
  }
  &--hierarchy-wrapper {
    @include page-content-layout();
    @include page-content-h-padding();
    @include v-padding-top($spacing-small, $spacing-medium, $spacing-medium);
  }

  &--hierarchy {
    border-bottom: 1px solid $black;
    padding-bottom: $spacing-medium;

    @media #{$tablet-screen-up} {
      @include grid-no-gap();
      grid-template-columns: 3fr 9fr;
    };

    &--label,
    &--value {
      border-top: 1px solid $jmb-gray-divider;
      &:first-child,
      &:nth-child(2n) {
        border-top: none;
      }
      @media #{$tablet-screen-up} {
        &:nth-child(2n) {
          border-top: 1px solid $jmb-gray-divider;
        }
        &:nth-child(2) {
          border-top: none;
        }
      }
    }

    &--label {
      @include font-body();
      margin: 0;
      padding-top: 0.9rem;
      padding-bottom: 0.6rem;
    }

    &--value {
      @include font-body-medium();
      padding-bottom: 0.9rem;

      &.term {
        @include font-h2-subhead-medium();
        margin: 0;
      }

      &--list-item {
        display: inline-block;
      }
    }

    &--label,
    &--value {
      @media #{$tablet-screen-up} {
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
      }
      &.term {
        @media #{$tablet-screen-up} {
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
        }
      }
    }

    &--checkbox-item {
      display: flex;
      align-items: center;
      @include checkbox();
      &:not(:last-child) {
        margin-bottom: 0.6rem;
      }

      .link {
        margin-left: 0.4rem;
      }
    }

    &--submit-btn {
      grid-column-start: 2;
      justify-self: start;
      margin-top: 1rem;
      @media #{$tablet-screen-up} {
        margin-top: 1.6rem;
      }
    }


    // adds the grid-column-gap
    &--value {
      @media #{$tablet-screen-up} {
        padding-left: $grid-gutter--tablet;
      };
      @media #{$desktop-screen-up} {
        padding-left: $grid-gutter--desktop;
      };
    }
    &--submit-btn {
      @media #{$tablet-screen-up} {
        margin-left: $grid-gutter--tablet;
      };
      @media #{$desktop-screen-up} {
        margin-left: $grid-gutter--desktop;
      };
    }
  }
}