.homepage {
  &--header {
    background-color: $jmb-darkblue;

    &--content {
      @include page-content-layout();
      @include page-content-h-padding();
      @include page-content-v-padding-big();
    }

    &--title {
      @include font-homepage-top-teaser-header();
      margin-bottom: 1.3rem;
      
      @media #{$tablet-screen-up} {
        margin-bottom: $spacing-small;
      }
    }
    &--text {
      @include font-h2-subhead-medium-white();
      margin-bottom: $spacing-medium;
      @media #{$tablet-screen-up} {
        margin-bottom: 3.4rem;
      }
    }
  }
}