.page-body {
  position: relative;
  display: flex;
  flex-direction: column;

  #page-footer {
    margin-top: auto;
  }

  &--content {
    @include page-content-layout();
    @include page-content-v-padding-big();
    @include page-content-h-padding();
  }
}