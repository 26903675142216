section[class^="admin-comment--"] {
	padding: 10px 15px;
}

.admin-comment {
	&--header {
		display: flex;
		flex-direction: row;
		align-items: center;

		h1 {
			margin-bottom: 0;
		}

		>*+* {
			margin-left: 2rem;
		}
	}

	&--content-wrapper {
		display: flex;
		flex-direction: row;
	}

	&--main {
		flex: 1 0 75%;

		>*+* {
			margin-top: $spacing-medium;
		}
	}

	&--sidebar {
		flex: 1 0 20%;

		font-family: $base-font-family;
		strong {
			font-weight: 600;
		}
	}

	&--data {
		border: 1px solid rgba(1, 1, 1, 0.3);

		>div {
			display: flex;
		}

		>*+* {
			margin-top: 0.5rem;
		}
	}

	&--data-label {
		width: 8rem;
		margin-right: 1rem;
	}

	&--data-value {
		flex: 1;

		&#objTitle {
			color: $jmb-berry;
		}

		&#comment {
			background-color: rgba(1, 1, 1, 0.1);
			padding: 5px 10px;
		}

		&#publicValue {
			color: $jmb-berry;
		}

		&#editor {
			display: flex;
			align-items: center;

			select {
				margin: 0;
				flex: 0.8;
			}

			button {
				margin-left: 1rem;
			}
		}
	}

	&--internal-comment {
		background-color: palegoldenrod;

		form {
			display: flex;

			label {
				width: 8rem;
			}

			div {
				flex: 1;
			}
		}
	}

	&--contact-form {
		background-color: rgba(1, 1, 1, 0.1);

		form>* {
			display: flex;

			label {
				width: 8rem;
			}

			input,
			textarea {
				flex: 1;
			}
		}

		form>*+* {
			margin-top: 0.5rem;
		}

		input[type=submit] {
			margin-left: 8rem;
		}

		.contact-form--value#templates {
			display: flex;
			flex-direction: column;

			button {
				color: $jmb-blue;
				cursor: pointer;
				font-size: 1rem;
				text-align: initial;

				&:hover {
					color: $jmb-berry;
				}
			}
		}
	}

	&--toolbar {
		display: flex;

		>*+* {
			margin-left: 1rem;
		}
	}
}