figure {
  margin: 0;
}

img,
picture,
video {
  margin: 0;
  display: block;
  width: 100%;
  height: auto;
}
