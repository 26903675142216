.search-section {
  background-color: $jmb-darkblue;
  
  &--content {
    @include page-content-layout();
    @include page-content-h-padding();
    @include v-padding($spacing-small, $spacing-small, $spacing-medium);

    &:not(.minimal) {
      @include grid();
      grid-row-gap: $grid-gutter--tablet;

      grid-template-areas: 
        'searchbox searchbox'
        'hits view-controls';
      align-items: center;
      
      @media #{$tablet-screen-up} {
        grid-template-areas: 
          'hits searchbox view-controls'
      }
    }
  }

  &--hits {
    grid-area: hits;
    @include font-h2-subhead-medium-white();
  }

  &--searchbox {
    grid-area: searchbox;
    
    form {
      display: flex;
      background-color: $white;
      padding: to-rem(20px) to-rem(14px);
      @media #{$tablet-screen-up} {
        padding: to-rem(24px) to-rem(28.8px);
      }
    }

    input {
      @include font-input-search();
      background-color: $white;
      border: none;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      padding: 0;


      &::placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder {
        color: $jmb-searchinput-placeholder-color;
      }
      &:focus {
        box-shadow: none;
      }
    }

    &--button {
      margin: 0;
      padding-left: to-rem(16px);
      &:hover {
        cursor: pointer;
      }
    }

    img {
      width: to-rem(28px);
      height: auto;

      @media #{$tablet-screen-up} {
        width: to-rem(30.4px);
      }
    }
  }

  &--view-controls {
    grid-area: view-controls;
    justify-self: end;
    &--button {
      @include jmb-circular-icon-button();
      display: inline-block;
      
      &:first-child {
        margin-right: to-rem(6.4px);
        @media #{$tablet-screen-up} {
          margin-right: to-rem(16px);
        }
      }

      &.active {
        border: 1px solid $jmb-berry;
        background-color: $jmb-berry;
      }

      &:not(.active) {
        border: 1px solid $jmb-darkblue;
        background-color: $white;
      }
    }
  }

  &--see-also-keyword {
    grid-area: see-also-kw;
    @include font-body($color: $white);
    margin-top: to-rem(16px);
    a {
      @include font-see-also-kw-link();
    }
  }
}