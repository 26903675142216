/* @file
* This file is a custom file that loads all files. Each non-base layer
* can be disabled.
*
* Do not name this file "STARTER.scss". This file should be named
* THEMENAME.scss. If you used the drush command to generate this subtheme,
* it should be named for you.
*
* This application file (ntest.scss) is where all the partials are
* imported.
*/

// Make sure the charset is set appropriately
@charset "UTF-8";
// Import Bourbon.
@import "../../node_modules/bourbon/core/bourbon";
// Import Neat.
@import "../../node_modules/bourbon-neat/core/neat";
// Import mixins and missing Neat 1.x features from base theme.
@import "../../../contrib/om_neato/source/scss/toppings";


@import "base/variables";

// Import variables and mixins.
@import "variables/colors";
@import "variables/variables"; // import base-font-size first
@import "variables/breakpoints";
@import "variables/font-faces";
@import "variables/fonts";
@import "variables/buttons";
@import "variables/grid-settings";


// Import element reset based on Bitters.
@import "base/base";


// Import Drupal element overrides
@import "common/button";
@import "common/form-item";
@import "common/form";
@import "common/grid";
@import "common/masonry";
// @import "common/messages";
@import "common/page";
@import "common/tables";
@import "common/layout";

// Import your components here.
@import "components/active-filters";
@import "components/admin-comment";
@import "components/admin-user";
@import "components/attr";
@import "components/comments";
@import "components/cookies";
@import "components/details";
@import "components/dummyThumbnail";
@import "components/facets";
@import "components/facetpanel";
@import "components/filters";
@import "components/homepage";
@import "components/keywordpage";
@import "components/link";
@import "components/menu";
@import "components/mood-teaser";
@import "components/page-admin";
@import "components/page-body";
@import "components/page-footer";
@import "components/page-header";
@import "components/pager";
// @import "components/questions";
@import "components/scroll-top-button";
@import "components/search-section";
@import "components/search";
@import "components/searchresult";
@import "components/simple-page";
@import "components/slider";
@import "components/systematic";
@import "components/teaser";


.hidden {
  display: none;
}

em {
  font-style: normal;
  background-color: $jmb-yellow;
  padding: 4px 0;
}

li {
  list-style: none;
}