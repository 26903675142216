// Define root variables that have to be accessible for calculation

// Font size in px which is used to set/guess the relative base font size of the html element
// using percentage($base-font-size / $em-base)
// Should NOT be used directly! Use rem font sizes or font-size mixin instead.
$base-font-size: 16px;

// Border
$base-border: 1px solid $jmb-gray-border;
$base-border-radius: 0;
