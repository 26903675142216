/**
  Adds a horizontal padding to an outer container which is defined by the current grid
 */
@mixin grid-outer-padding($substract-gutter: false, $grid: $neat-grid) {
  $_padding-value: _retrieve-neat-setting($grid, outer-padding);

  @if $substract-gutter {
    $_grid-gutter: _retrieve-neat-setting($grid, gutter);

    @if $_padding-value == 0 {
      $_padding-value: 0rem;
    }

    @if unit($_padding-value) != unit($_grid-gutter) {
      @error 'Incompatible units for gutter and outer-padding'
    }
    @if $_grid-gutter > $_padding-value {
      @error 'The substract-gutter option does not support gutter exceeding outer-padding'
    }

    $_padding-value: $_padding-value - $_grid-gutter;
  }

  @include padding(null $_padding-value);
}

/**
  Adds a horizontal padding to an outer container which is defined by each grid depending on screen size
 */
@mixin grid-outer-padding-all($substract-gutter: false, $grids: $all-screen-grids) {
  @include grid-media($grids...) {
    @include grid-outer-padding($substract-gutter);
  }
}
