.filters {
  margin-bottom: 1.3rem; // 1.9 - 0.6

  @media #{$tablet-screen-up} {
    margin-bottom: 2.4rem;
  }

  &--label,
  &--no-filter-selected {
    display: none;
    @include font-body();
    margin-right: 0.9rem;
    margin-bottom: 0.6rem;
    @media #{$tablet-screen-up} {
      display: inline-block;
    }
  }
  &--label {
    @include font-body();
  }
  &--no-filter-selected {
    @include font-body($color: rgba(1,1,1,.6));
  }

  &--list {
    display: inline;
  }

  &--button {
    display: inline-block;
    margin-right: 0.6rem;
    margin-bottom: 0.6rem;
    background-color: $jmb-lightgray;
    border-radius: 99px;

    a {
      @include font-filter();
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.6rem 0.9rem;
    }
    img {
      height: 0.8rem;
      width: auto;
      margin-left: 0.6rem;
      margin-top: -2px;
    }
  }
}