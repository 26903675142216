// Color definitions
$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);

$jmb-berry: #AA005F;
$jmb-lightblue: #8286BA;
$jmb-blue: #000073;
$jmb-darkblue: #010061;
$jmb-red: #FF2346;
$jmb-yellow: #FFF44F;
$jmb-lightgray: #EAEAEA;

$jmb-gray-border: #979797; // used for boxes border
$jmb-gray-divider: #E0E0E0; // used for lines and separators

$jmb-searchinput-placeholder-color: #898989;


// Base colors
$base-background-color: $white !default;

// Font Colors
$base-font-color: $black !default;
$base-link-color: $jmb-berry !default;
$action-color: $jmb-berry !default;

// Table colors.
$table-border-color: $base-border-color;
$table-background: $base-background-color;
$table-header-background: lighten($table-background, 10);
$table-hover-background: darken($table-background, 2);
// $table-stripe-background: darken($table-background, 4);
// $table-stripe-background-hover: darken($table-stripe-background, 5);

// Tasks colors.
$tab-link-color: $dark-gray;
$tab-active-background: $base-background-color;
$tab-inactive-color: $base-background-color;
// $tab-inactive-hover-color: silver;

// Messages colors.
$alert-color: #fff6bf !default;
$error-color: #fbe3e4 !default;
$notice-color: #e5edf8 !default;
$success-color: #e6efc2 !default;

