.page-footer {
  background-color: $jmb-red;

  &--navigation {
    @include page-content-layout();
    @include page-content-h-padding();
    @include v-padding($spacing-2_8rem, $spacing-4_6rem, $spacing-huge);
    
    @include font-footer();
    @include grid();
    grid-template-columns: 1fr;

    @media #{$tablet-screen-up} {
      grid-template-columns: 3fr 3fr 6fr;
    }

    li {
      margin-bottom: to-rem(8px);

      img {
        max-height: 100%;
        width: auto;
        display: inline-block;
        margin-right: $spacing-tiny;
      }
    }
  }

  &--menu-1 {
    margin-bottom: to-rem(50px);
  }

  &--branding {
    justify-self: end;
    display: none;
    @media #{$tablet-screen-up} {
      display: block;
    }

    &--logo {
      height: to-rem(104px);
      width: auto;
    }
  }
}
