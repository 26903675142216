.search {
  &--content {
    @include page-content-layout();
    @include page-content-h-padding();
    @include v-padding($spacing-small, $spacing-medium, $spacing-medium);
  }

  &--main-content {
    @include grid();
    grid-template-columns: 1fr;
    grid-template-areas:
      "results"
      "facetpanel";

    &--grid-view {
      @media #{$tablet-screen-up} {
        grid-template-columns: 4fr 8fr;
        grid-template-areas:
          "facetpanel results";
      }
    }

    &--list-view {
      @media #{$tablet-screen-up} {
        grid-template-columns: 4fr 1fr 7fr;
        grid-template-areas:
          "facetpanel . results";
      }
    }
  }

  &--results {
    grid-area: results;
  }
}
