.simple-page {
  
  h1 {
    @include font-simple-page-h1();
  }

  h2 {
    @include font-simple-page-h2();
  }
  
  h3 {
    @include font-simple-page-h3();
    margin-top: 1.25em;
  }
  
  strong {
    font-weight: $fw-bold;
  }
  
  a {
    @include font-link();
  }
}