@mixin checkbox() {
  .form--checkbox--input {
    @include hide-visually;
  }

  .form--checkbox--button {
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    cursor: pointer;

    @include hover {
      .form--checkbox--label {
        text-decoration: underline;
        color: $jmb-berry;
      }
    }
  }

  .form--checkbox--label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
  }

  .form--checkbox--image {
    width: 1.5rem;
    min-width: 1.5rem; // ensures width in ff mobile when there's a negative margin-top
    height: auto;
    margin-right: 0.6rem;
  }
}

@mixin radioButton() {
  .form--radio--input {
    @include hide-visually;
  }

  .form--radio--button {
    border: 1px solid transparent;
    display: flex;
    align-items: flex-start;
    white-space: unset;
    cursor: pointer;

    // fixes cut image in safari
    padding-top: to-rem(5px);
  }

  .form--radio--image {
    width: to-rem(24px);
    min-width: to-rem(24px); // ensures width in ff mobile
    height: auto;
    margin-right: to-rem(6px);
    margin-top: to-rem(-5px);
  }

  .form--radio--label {
    @include font-body();
    cursor: pointer;
    margin-bottom: 0;
    text-align: left;
  }
}
