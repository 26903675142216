.comments {
  &--hr {
    border-top: 1px solid $black;
    margin: $spacing-medium 0;
    @media #{$tablet-screen-up} {
      margin: 5rem 0;
    }
  }

  &--title {
    @include font-h2-subhead-bold();
  }
  
  &--subheader {
    @include font-h2-subhead-medium();
  }

  &--form {
    @media #{$tablet-screen-up} {
      max-width: 70%;
    }
    @media #{$tablet-screen-up} {
      max-width: 50%;
    }
  }

  &--feedback {
    margin-top: to-rem(16px);
    color: green;
  }

  &--list {
    margin-bottom: $spacing-medium;
    
    &--item {
      margin-bottom: $spacing-small;
      @include grid();
      grid-row-gap: 0.125rem;
      grid-template-columns: 1fr;
      grid-template-areas:
        "date"
        "name"
        "message";

      @media #{$tablet-screen-up} {
        grid-template-columns: 1fr 8fr;
        grid-template-areas:
          "date name"
          ".    message";
      }
      
      &--date {
        grid-area: date;
      }
      &--name {
        grid-area: name;
        @include font-comments-user();
      }
      &--message {
        grid-area: message;
      }
    }
  }
}