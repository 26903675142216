///
/// Transforms a list of values with an absolute unit like px to a relative one using a common base-value.
/// If the resulting list contains only one element, it will be returned instead of the list.
///
/// @param {list} $values - Each value must contain the same unit.
/// @param {number} $base-value - Must contain the same unit like each value in $values.
/// @param {number} $factor - Can be used to change the resulting unit via multiplication.
///
@function to-relative($values, $base-value, $factor: 1) {
  $new-values: ();

  @each $value in $values {
    @if ($value != null) {
      @if unit($value) != unit($base-value) {
        @error 'Incompatible units for base-size and value'
      }

      $new-value: (strip-unit($value) / strip-unit($base-value)) * $factor;
      $new-values: append($new-values, $new-value);
    }
  }

  @if length($new-values) == 1 {
    @return nth($new-values, 1);
  } @else {
    @return $new-values;
  }
}

/// @see $em-base
@function to-em($values, $base-value: $em-base) {
  @return to-relative($values, $base-value, 1em)
}

/// @see $base-font-size
@function to-rem($values) {
  @return to-relative($values, $base-font-size, 1rem)
}
