$masonry-col-gap: 20.8px;

// @mixin masonry() {
//   display: flex;
//   // &:after {
//   //   display: block;
//   //   content: '';
//   //   clear: both;
//   // }

//   .masonry-col {
//     flex: 1;
//     // float: left;
//     margin-right: 0;
//     width: 100%;
//   }

//   .masonry-col--2,
//   .masonry-col--3 {
//     display: none;
//   }

//   @media #{$tablet-screen-up} {
//     .masonry-col {
//       width: calc((100% / 2) - 20.8px);
//       margin-right: $masonry-col-gap;
//     }

//     .masonry-col--2 {
//       display: block;
//       margin-right: 0;
//     }
//   }
  
//   @media #{$desktop-screen-up} {
//     .masonry-col {
//       width: calc((100% / 3) - 20.8px);
//     }

//     .masonry-col--2 {
//       margin-right: $masonry-col-gap;
//     }

//     .masonry-col--3 {
//       display: block;
//       margin-right: 0;
//     }
//   }
// }


@mixin masonry() {
  column-gap: $masonry-col-gap;
  .masonry-item {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    width: 100%;
  }
}


// @mixin masonry-homepage-teasers() {
//   @include masonry();
//   columns: 1;
//   @media #{$mobile-wide-screen-up} {
//     columns: 2;
//   }
//   @media #{$tablet-screen-up} {
//     columns: 3;
//   }
// }

@mixin masonry-search-results() {
  @include masonry();
  columns: 1;
  @media #{$mobile-wide-screen-up} {
    columns: 2;
  }
  @media #{$desktop-screen-up} {
    columns: 3;
  }
}
