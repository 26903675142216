// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 0;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$medium-spacing: $base-spacing;
$large-spacing: $base-spacing * 2;
$base-z-index: 0;

// Colors
$blue: #1565c0;
$dark-gray: #333;
$medium-gray: #E6E6E6;
$light-gray: #F7F7F7;

// Font Colors
// $base-font-color: $dark-gray !default; // defined in variables/colors
// $action-color: $blue !default; // defined in variables/colors

// Border
$base-border-color: #979797;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: $light-gray;
$secondary-background-color: tint($base-border-color, 75%);

// Animations
$base-duration: 150ms;
$base-timing: ease;
