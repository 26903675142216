button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  appearance: none;
  background-color: transparent;
  white-space: nowrap;
  border: none;
  padding: 0;
}

button.button,
a.button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  @include button;
}
