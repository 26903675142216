@mixin button() {
  @include font-body-medium();
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color border-color $base-duration $base-timing;
  user-select: none;
  vertical-align: middle;

  border-radius: $base-border-radius;
  padding: $small-spacing $base-spacing;

  // @include hover-or-focus {}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    // @include hover {}
  }
}
