.page-admin {
  &--menu {
    display: flex;
    flex-direction: column;
    a {
      padding: $small-spacing 0;
    }
  }

  &--navigation {
    background-color: $jmb-darkblue;

    &--content {
      @include page-content-layout();
      @include page-content-h-padding();
      @include v-padding($spacing-small, $spacing-small, $spacing-small);
      display: flex;
      flex-direction: column;

      @media #{$tablet-screen-up} {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    a {
      @include font-navigation-object();
      padding: $small-spacing 0;
      display: flex;
      align-items: center;
      
      &.page-admin--navigation--standout-link {
        border: 1px solid currentColor;
        padding-left: to-rem(16px);
        padding-right: to-rem(16px);
      }
    }

    img {
      width: auto;
      height: to-rem(18px);
      margin-right: to-rem(7px);
      margin-top: to-rem(-3px);
    }
  }

  &--table {
    &--column {
      &.center {
        text-align: center;
      }
    }
  }

  &--user-form {
    #user-roles {
      display: flex;
      flex-direction: column;
      margin-top: 2.4rem;

      .form--checkbox {
        margin: 0;
        &--button {
          margin: 0.5rem 1rem;
        }
      }

      @media #{$tablet-screen-up} {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  &--notifications {
    &-OK { color: green; }
    &-ERROR { color: $jmb-berry; }
  }

  &--search-type-form {
    margin: .75em 0;
    
    button {
      @include font-body-medium();
      border: 1px solid currentColor;
      padding: to-rem(10px) to-rem(16px);
      display: inline-block;
      transition: color $base-duration $base-timing;
      cursor: pointer;

      @include hover-or-focus() {
        border-color: $jmb-berry;
        color: $jmb-berry;
      }
    }
  }
}


// Overrides jquery datatables
.dataTables {
  &_wrapper {
    margin-top: $medium-spacing;
  }

  &_length label {
    display: flex;
    flex-direction: row;
    select {
      margin: 0 5px;
    }
  }

  &_filter input {
    background-color: $white;
    border-radius: 0;
  }

  &_info,
  &_paginate {
    margin-top: $medium-spacing;
  }

  &_paginate .paginate_button {
    @include font-link();
    background: transparent !important;

    &:hover {
      color: #000 !important
    }
  }
}

table.dataTable thead th {
  font-weight: $fw-bold;
}