.pager {
  margin-bottom: $spacing-small;
  display: flex;
  @media #{$tablet-screen-up} {
    justify-content: flex-end;
  }

  &--list {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--item {
      margin-left: 1.3rem;
      &:first-child {
        margin-left: 0;
      }
      
      &.active {
        color: $jmb-berry;
      }
      // a:focus {
      //   outline: auto;
      // }
    }
  }

  &--page {
    @include font-pager();
    display: block;
    text-align: center;
    min-width: 1.3rem;
    height: 1.3rem;

    a, span {
      display: block;
      margin-top: 0.1rem;
    }
  }

  &--button {
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }

    img {
      height: 1.3rem;
      width: auto;
    }
  }
}



