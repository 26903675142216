
.mood-teaser{
	margin-top: 2rem;
	margin-bottom: 2rem;
	background-color: $light-gray;
}


.mood-teaser--body{
	display: flex;	
	//padding: 2rem 2rem 2rem 2rem;
	max-width: 1050px;	
	margin: 0 auto;
}

.mood-teaser--figure{
	flex-basis: 50%;
	margin-rigth: 10px;
}

.mood-teaser--image{
}

.mood-teaser--details{	
	margin-left: 10px;
	flex-basis: 50%;
}