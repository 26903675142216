$base-font-family: "JmbPro", sans-serif; // $font-stack-system;
$heading-font-family: "Headline", $base-font-family;

// ---- styleguide fonts ----
@mixin font-main-navigation() {
  @include font-size($font-size: 25px, $line-height: 25px);
  font-family: $base-font-family;
  font-weight: $fw-medium;
  color: $white;

  // styleguide name: main-navigation-2
  @media #{$tablet-screen} {
    @include font-size($font-size: 20px, $line-height: 24px);
  }
}

@mixin font-navigation-object() {
  @include font-size($font-size: 18px, $line-height: 18px);
  font-family: $base-font-family;
  font-weight: $fw-medium;
  color: $white;
}

@mixin font-hd2-white-headline() {
  @include font-size($font-size: 32px, $line-height: 32px);
  font-family: $heading-font-family;
  font-feature-settings: "ss01";
  font-weight: $fw-regular;
  color: $white;
  text-align: center;

  @media #{$tablet-screen-up} {
    @include font-size($font-size: 72px, $line-height: 64px);
  }
}

@mixin font-h2-headline-small-bold() {
  @include font-size($font-size: 28px, $line-height: 32px);
  font-family: $base-font-family;
  font-weight: $fw-bold;
  color: $base-font-color;

  @media #{$tablet-screen-up} {
    @include font-size($font-size: 35px, $line-height: 40px);
  }
}

@mixin font-h2-subhead($weight: $fw-medium, $color: $base-font-color) {
  @include font-size($font-size: 20px, $line-height: 24px);
  font-family: $base-font-family;
  font-weight: $weight;
  color: $color;
  
  @media #{$tablet-screen-up} {
    @include font-size($font-size: 25px, $line-height: 30px);
  }
}
@mixin font-h2-subhead-medium() {
  @include font-h2-subhead();
}
@mixin font-h2-subhead-medium-white() {
  @include font-h2-subhead($color: $white);
  opacity: 0.9;
}
@mixin font-h2-subhead-bold() {
  @include font-h2-subhead($weight: $fw-bold);
}

@mixin font-input-search() {
  @include font-size($font-size: 22px, $line-height: 24px);
  font-family: $base-font-family;
  font-weight: $fw-medium;
  color: $base-font-color;
  // placeholder color: $jmb-searchinput-placeholder-color
}

@mixin font-h-teaser($weight) {
  @include font-size($font-size: 22px, $line-height: 30px);
  font-family: $base-font-family;
  font-weight: $weight;
  color: $base-font-color;
}
@mixin font-h-teaser-black() {
  @include font-h-teaser($weight: $fw-black);
}
@mixin font-h-teaser-medium() {
  @include font-h-teaser($weight: $fw-medium);
}

@mixin font-body($weight: $fw-regular, $color: $base-font-color) {
  @include font-size($font-size: 16px, $line-height: 22px);
  font-family: $base-font-family;
  font-weight: $weight;
  color: $color;
}
@mixin font-body-medium() {
  @include font-body($weight: $fw-medium);
}
@mixin font-body-medium-white() {
  @include font-body($weight: $fw-medium, $color: $white);
}

@mixin font-footer() {
  @include font-size($font-size: 15px, $line-height: 20px, $letter-spacing: 0.25px);
  font-family: $base-font-family;
  font-weight: $fw-medium;
  color: $base-font-color;
}

@mixin font-pager() {
  @include font-size($font-size: 18px, $line-height: 18px);
  font-family: $base-font-family;
  font-weight: $fw-medium;
  color: $base-font-color;
  // styleguide pager-on: color: $jmb-berry
}

@mixin font-filter($color: $base-font-color) {
  @include font-size($font-size: 14px, $line-height: 16px);
  font-family: $base-font-family;
  font-weight: $fw-medium;
  color: $color;
}
@mixin font-filter-negative() {
  @include font-filter($color: $white);
}

@mixin font-copy($weight, $color: $base-font-color) {
  @include font-size($font-size: 19px, $line-height: 26px);
  font-family: $base-font-family;
  font-weight: $weight;
  color: $color;
}
@mixin font-copy-medium() {
  @include font-copy($weight: $fw-medium);
}
@mixin font-copy-medium-white() {
  @include font-copy($weight: $fw-medium, $color: $white);
}
@mixin font-copy-bold() {
  @include font-copy($weight: $fw-bold);
}
@mixin font-copy-bold-white() {
  @include font-copy($weight: $fw-bold, $color: $white);
}
@mixin font-copy-bold-white-phone() {
  @include font-size($font-size: 18px, $line-height: 26px);
  font-family: $base-font-family;
  font-weight: $fw-bold;
  color: $white;
}

@mixin font-copy-large($weight) {
  @include font-size($font-size: 22px, $line-height: 30px);
  font-family: $base-font-family;
  font-weight: $weight;
  color: $base-font-color;
}
@mixin font-copy-large-medium() {
  @include font-copy-large($weight: $fw-medium);
}
@mixin font-copy-large-bold() {
  @include font-copy-large($weight: $fw-bold);
}
// ---- styleguide fonts ----


@mixin font-h1() {
  @include font-h2-headline-small-bold();
}
@mixin font-h3() {
  @include font-copy-large-medium();
}

@mixin font-link() {
  @include font-body-medium();
  text-decoration: none;
  transition: color $base-duration $base-timing;
  cursor: pointer;
  
  &:active,
  &:hover {
    text-decoration: underline;
    color: $jmb-berry;
  }
}

@mixin font-facet-link() {
  @include font-body-medium();
  text-decoration: none;
  transition: color $base-duration $base-timing;
}
@mixin font-facet-link-hover() {
  color: $jmb-berry;
  text-decoration: underline;
}

@mixin font-link-light() {
  @include font-body();
  text-decoration: underline;
  &:active,
  &:hover {
    color: $jmb-berry;
  }
}

@mixin font-link-light-color($color) {
  @include font-body();
  text-decoration: underline;
  color: $color;
  &:active,
  &:hover {
    color: $base-font-color;
  }
}

@mixin font-cookies() {
  @include font-footer();
  color: $white;
}

@mixin font-homepage-top-teaser-header() {
  @include font-hd2-white-headline();
  text-transform: uppercase;
}

@mixin font-see-also-kw-link() {
  @include font-body($weight: $fw-medium, $color: $white);
  text-decoration: none;
  transition: color $base-duration $base-timing;
  cursor: pointer;
  
  &:active,
  &:hover {
    text-decoration: underline;
  }
}

@mixin font-facet-settings() {
  @include font-filter-negative();
  &:hover {
    text-decoration: underline;
  }
}


@mixin font-simple-page-header() {
  font-family: $base-font-family;
  font-weight: $fw-bold;
  color: $base-font-color;
}
@mixin font-simple-page-h1() {
  @include font-simple-page-header();
  @include font-size($font-size: 32px, $line-height: 32px);
  @media #{$tablet-screen-up} {
    @include font-size($font-size: 64px, $line-height: 57px);
  }
}
@mixin font-simple-page-h2() {
  @include font-simple-page-header();
  @include font-size($font-size: 28px, $line-height: 32px);
  @media #{$tablet-screen-up} {
    @include font-size($font-size: 35px, $line-height: 40px);
  }
}
@mixin font-simple-page-h3() {
  @include font-simple-page-header();
  @include font-size($font-size: 20px, $line-height: 24px);
  @media #{$tablet-screen-up} {
    @include font-size($font-size: 25px, $line-height: 30px);
  }
}

@mixin font-comments-user() {
  font-family: $base-font-family;
  font-weight: $fw-medium;
  color: $base-font-color;
}

