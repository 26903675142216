.page {

  &--one-sidebar,
  &--wrapped-content {

    .page__content-wrapper {
      @include grid-wrapper();
      @include grid-container();
    }

    .page__breadcrumb,
    .page__content {
      @include grid-column-all();
    }
  }

  &--one-sidebar {

    .page__sidebar {
      @include grid-column-all();
    }

    @include grid-media($tablet-wide-screen-grid, $desktop-screen-grid) {

      .page__content {
        @include grid-column(8);
      }

      .page__sidebar {
        @include grid-column(4);
      }
    }
  }
}
