.active-filters {
  display: flex;
  max-width: 100%;

  &--label {
    flex: 0 0 auto;
    margin-right: 15px;
  }

  &--list {
    display: flex;
    flex-wrap: wrap;
  }

  &--item {
    border: 1px solid $jmb-red;
    border-radius: 555px;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 0.4rem 0.7rem;
  }

}