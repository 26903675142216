// Mobile width: 280px - 2 columns: 130px
$grid-gutter--mobile: to-rem(20px);

// Tablet width: 918px - 12 columns: 60px
$grid-gutter--tablet: to-rem(18px);

// Desktop width: 1132px - 12 columns: 76px
$grid-gutter--desktop: to-rem(20px);


@mixin grid-no-gap() {
  display: -ms-grid;
  display: grid;
  grid-template-rows: auto;
  -ms-grid-rows: auto;
}

@mixin grid() {
  display: -ms-grid;
  display: grid;
  grid-template-rows: auto;
  -ms-grid-rows: auto;

  grid-column-gap: $grid-gutter--mobile;

  @media #{$tablet-screen-up} {
    grid-column-gap: $grid-gutter--tablet;
  };

  @media #{$desktop-screen-up} {
    grid-column-gap: $grid-gutter--desktop;
  };
}