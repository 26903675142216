//Screen width including grid gutter
// $content-width: 1050px + (24px * 2);
$content-width-tablet: 918px + (18px * 2);
$content-width-desktop: 1132px + (20px * 2);

$spacing-0_6rem: to-rem(9.6px);
$spacing-1_9rem: to-rem(30px);
$spacing-2_5rem: to-rem(40px);
$spacing-2_8rem: to-rem(45px);
$spacing-4_6rem: to-rem(74px);
$spacing-6_3rem: to-rem(100px);

$spacing-tiny: $spacing-0_6rem;
$spacing-small: $spacing-1_9rem;
$spacing-medium: $spacing-2_5rem;
$spacing-huge: $spacing-6_3rem;


@mixin page-content-layout() {
  margin-left: auto;
  margin-right: auto;

  @media #{$tablet-screen-up} {
    max-width: $content-width-tablet;
  }
  @media #{$desktop-screen-up} {
    max-width: $content-width-desktop;
  }
}

@mixin v-padding($p-mobile, $p-tablet, $p-desktop) {
  padding-bottom: $p-mobile;
  padding-top: $p-mobile;
  @media #{$tablet-screen-up} {
    padding-bottom: $p-tablet;
    padding-top: $p-tablet;
  }
  @media #{$desktop-screen-up} {
    padding-bottom: $p-desktop;
    padding-top: $p-desktop;
  }
}
@mixin v-padding-top($p-mobile, $p-tablet, $p-desktop) {
  padding-top: $p-mobile;
  @media #{$tablet-screen-up} {
    padding-top: $p-tablet;
  }
  @media #{$desktop-screen-up} {
    padding-top: $p-desktop;
  }
}


@mixin h-padding($p-mobile, $p-tablet, $p-desktop) {
  padding-left: $p-mobile;
  padding-right: $p-mobile;
  @media #{$tablet-screen-up} {
    padding-left: $p-tablet;
    padding-right: $p-tablet;
  }
  @media #{$desktop-screen-up} {
    padding-left: $p-desktop;
    padding-right: $p-desktop;
  }
}

@mixin page-content-h-padding() {
  @include h-padding($grid-gutter--mobile, $grid-gutter--tablet, $grid-gutter--desktop);
}

@mixin page-content-v-padding-big() {
  @include v-padding($spacing-medium, 3.8rem, $spacing-huge);
}

@mixin page-header-v-padding() {
  @include v-padding(1.6rem, 2.2rem, $spacing-medium);
}

@mixin margin-bottom($p-mobile, $p-tablet, $p-desktop) {
  margin-bottom: $p-mobile;
  @media #{$tablet-screen-up} {
    margin-bottom: $p-tablet;
  }
  @media #{$desktop-screen-up} {
    margin-bottom: $p-desktop;
  }
}
