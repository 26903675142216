@mixin menuItem() {
  a:hover,
  &.active a {
    border-bottom: 2px solid $jmb-lightblue;
  }
}

.menu {
  &--mobile--button > img {
    width: 30px;
  }

  &--mobile {
    @include page-content-h-padding();
    @include page-header-v-padding();
    &:not(.active) {
      display: none;
    }
    @media #{$tablet-screen-up} {
      display: none;
    }

    background-color: $jmb-blue;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    &--panel-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &--default {
    display: none;

    @media #{$desktop-screen-up} {
      display: block;
    }
  }

  &--navigation {
    @include font-main-navigation();

    &--list {
      display: flex;
      flex-direction: column;

      @media #{$tablet-screen-up} {
        flex-direction: row;
      }

      &-item {
        @include menuItem();
        margin-top: $medium-spacing;
        text-align: center;

        @media #{$tablet-screen-up} {
          text-align: left;
          margin-top: 0;
          margin-right: to-rem(20px);
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  &--admin {
    @media #{$tablet-screen-up} {
      background: $jmb-blue;
    }

    &--content {
      color: $white;

      @media #{$tablet-screen-up} {
        @include page-content-layout();
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
      }
    }

    &--list {
      display: flex;
      flex-direction: column;

      @media #{$tablet-screen-up} {
        margin-left: to-rem(16px);
        flex-direction: row;
        justify-content: flex-end;
      }

      &--item {
        margin-top: $medium-spacing;
        
        @media #{$tablet-screen-below} {
          @include menuItem();
          @include font-main-navigation();
          text-align: center;
        }
        @media #{$tablet-screen-up} {
          margin-top: 0;
          margin-left: $medium-spacing;
          a:hover,
          &.active a {
            text-decoration: underline;
          }
        }
      }
    }

    &--search-type-hint {
      display: none;
      @media #{$tablet-screen-up} {
        display: block;
      }
    }
  }
}
