$hover-supported :
  '(hover: hover),' +  // true hover feature detection supported by Chrome, Safari and Edge
  '(-ms-high-contrast: none), (-ms-high-contrast: active),' + // detects any IE (assuming hovering is available)
  '(-moz-touch-enabled: 0)'; // detects Firefox with touch disabled (assuming hovering is available)

/// Applies styles in @content to the current elements :hover state only if the device supports hovering
@mixin hover()
{
  @media #{$hover-supported} {
    &:hover {
      @content;
    }
  }
}

/// Applies styles in @content to the current elements :hover state only if the device supports hovering
/// @param {string} $additional-states-selector - Selector for states when @content should also be applied for any device
@mixin hover-or($additional-states-selector)
{
  @include hover() {
    @content;
  }
  #{$additional-states-selector} {
    @content;
  }
}

/// Applies styles in @content to the current elements :hover state only if the device supports hovering
/// and also to :active and :focus states for any device
@mixin hover-or-focus()
{
  @include hover-or("&:active, &:focus") {
    @content;
  }
}
