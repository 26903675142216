///
/// Recommended specification of font-size, letter-spacing and line-height using relative units.
/// @link https://wiki.innermedia.de/pages/viewpage.action?pageId=39388741
///
/// @param {Number} $font-size - font-size in px or rem. If using px or no unit all, it will be set in relation to $base-font-size with rem as unit.
/// @param {Number} $letter-spacing [null] - letter-spacing using the same unit as $font-size. It will be set in relation to $font-size with em as unit.
/// @param {Number} $line-height [null] - line-height using the same unit as $font-size. It will be set in relation to $font-size with no unit.
/// @see $base-font-size
///
@mixin font-size($font-size, $letter-spacing: null, $line-height: null) {
  @if $font-size != null {
    @if unitless($font-size) or unit($font-size) == px {
      font-size: (strip-unit($font-size) / strip-unit($base-font-size)) * 1rem;
    }
    @else {
      font-size: $font-size;
    }

    @if $letter-spacing != null {
      @if unit($letter-spacing) != unit($font-size) {
        @warn 'Incompatible units for font-size and letter-spacing'
      }
      letter-spacing: (strip-unit($letter-spacing) / strip-unit($font-size)) * 1em;
    }

    @if $line-height != null {
      @if unit($line-height) != unit($font-size) {
        @warn 'Incompatible units for font-size and line-height'
      }
      line-height: (strip-unit($line-height) / strip-unit($font-size));
    }
  }
}
