.searchresult {
  &--thumbnail {
    min-width: 200px;
    max-height: 100%;

    img {
      height: auto;
      width: 100%;
      max-width: 200px;
    }

    &--grid-view {
      margin-bottom: to-rem(16px);
      width: 100%;
      height: auto;
      img {
        max-width: 100%;
      }
    }
  }

  &--grid {
    @include masonry-search-results();
    &--item {
      margin-bottom: to-rem(40px);
    }
  }

  &--list {
    margin-bottom: to-rem(20px);

    &--item {
      border-top: 1px solid $jmb-gray-divider;
      &:last-child {
        border-bottom: 1px solid $jmb-gray-divider;
      }

      padding: to-rem(20px) 0;

      display: flex;
      flex-direction: column;
      
      @media #{$tablet-screen-up} {
        flex-direction: row;
        padding: to-rem(40px) 0;
      }

      &--content {
        margin-top: to-rem(16px);
        @media #{$tablet-screen-up} {
          margin-top: 0;
          margin-left: to-rem(16px);
        }
      }
    }
  }

  &--title {
    margin-bottom: 0.3rem;
    display: block;
    &:hover {
      text-decoration: underline;
    }

    h2 {
      .searchresult--list--item & {
        @include font-h2-subhead-bold();
      }
  
      .searchresult--grid--item & {
        @include font-copy-bold();
      }
    }

    img {
      display: inline;
      margin-left: to-rem(5px);
      width: auto;
      height: 100%;
    }
  }
}