@mixin jmb-button() {
  @include button();
  background-color: transparent;
  border-radius: 999px;
  border: 1px solid $black;
  color: $black;

  &:hover,
  &:active {
    background-color: $black;
    color: $white;
  }
  &:focus {
    box-shadow: none;
    box-shadow: 0 0 2px 1px $black;
  }
}

@mixin jmb-button-with-icon() {
  @include button();
  background-color: transparent;
  border-radius: 999px;
  border: 1px solid $black;
  color: $black;
  
  &:focus {
    box-shadow: none;
    box-shadow: 0 0 2px 1px $black;
  }
}


button.button,
a.button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  @include jmb-button();
}


@mixin jmb-button-white() {
  border-color: $white;
  color: $white;

  &:hover,
  &:active {
    background-color: $white;
    color: $black;
  }
}

@mixin jmb-circular-icon-button() {
  border-radius: 999px;
  padding: 0.6rem;

  @media #{$tablet-screen-up} {
    padding: 0.8rem;
  }

  img {
    width: 1.2rem;
    height: auto;
    @media #{$tablet-screen-up} {
      width: 1.5rem;
    }
  }

}