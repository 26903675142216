.cookies {
  &--banner {
    background-color: $jmb-blue;

    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 999;

    padding: $medium-spacing;
  }

  &--text {
    @include font-cookies();
    margin: $small-spacing 0;
  }

  &--content {
    @include grid();
    grid-template-columns: 1fr;
    align-items: center;
    grid-row-gap: $grid-gutter--tablet;

    @media #{$tablet-screen-up} {
      grid-template-columns: 2fr 1fr;
    }
  }

  &--toolbar {
    @media #{$tablet-screen-up} {
      justify-self: end;
    }

    .button {
      margin: $small-spacing 0;
      display: inline-block;
      @include jmb-button-white();
      &:first-child {
        margin-right: $medium-spacing;
      }
    }
  }
}