a.link {
  @include font-link();

  img {
    display: inline;
    // width: auto;
    // height: 100%;
  }
}

.link {
  &--doc-icon {
    width: 16px;
  }
}

a.linkBtn {
  @include font-link();
  color: white;

  background: $jmb-red;
  display: block;
  padding: 1rem 2rem;
  width: fit-content;

  &:active,
  &:focus,
  &:hover {
    color: white;
  }
}
