html {
  font-size: percentage($base-font-size / $em-base);
}

body {
  @include font-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

h2,
h4,
h5,
h6 {
  font-family: $base-font-family;
}

h1 {
  @include font-h1;
}

h3 {
  @include font-h3;
}

p {
  margin: 0 0 $small-spacing;
}

/* do not style anchor here, but in text-formatted class */
a {
  text-decoration: none;
  color: inherit;
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}
