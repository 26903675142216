@charset "UTF-8";

$max-width: 1200px !default;

@mixin outer-wrapper($local-max-width: $max-width) {
  max-width: $local-max-width;
  margin: {
    left: auto;
    right: auto;
  }
}
