.systematic {
  &--content{
    @include page-content-layout();
    @include page-content-v-padding-big();
    @include page-content-h-padding();
  }

  &--item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    @media #{$tablet-screen-up} {
      display: inline-block;
    }

    &.level-0 {
      border-top: 1px solid $black;
      &:last-child {
        border-bottom: 1px solid $black;
      }
    }
    &:not(.level-0) {
      border-top: 1px solid $jmb-gray-divider;
    }

    &.active,
    &:hover {
      background-color: $jmb-berry;
      .systematic--category {
        color: $white;
      }
    }
  }

  &--category {
    width: 100%;
    padding-right: 1rem;
    display: inline-block;
    text-align: left;
    white-space: normal;

    &:hover {
      cursor: pointer;
      color: $white;
    }

    .systematic--item.active & {
      color: $white;
    }

    &.level-0 {
      @include font-copy-large-medium();
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      padding-left: to-rem(5px);
      @media #{$tablet-screen-up} {
        padding-left: 0.9rem;
      }

      span.noicon {
        padding-left: 1.7rem;
      }
    }

    &:not(.level-0) {
      @include font-copy-medium();
      padding-top: 0.9rem;
      padding-bottom: 0.8rem;
      span.noicon {
        padding-left: 1.9rem;
      }
    }

    &.level-1 { padding-left: to-rem(40px); }
    &.level-2 { padding-left: to-rem(80px); }
    &.level-3 { padding-left: to-rem(120px); }
    &.level-4 { padding-left: to-rem(160px); }
    &.level-5 { padding-left: to-rem(200px); }
    &.level-6 { padding-left: to-rem(240px); }
  }

  &--keywords {
    background-color: $jmb-berry;
    padding: 1.3rem 1.3rem 1.3rem $spacing-medium;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    width: calc(100% + 2.5rem);
    z-index: 1;

    @media #{$tablet-screen-up} {
      padding: 1.3rem $spacing-small 1.6rem;
      margin: 0;
      width: 40%;
      position: absolute;
      top: 0;
      right: 0;
    }

    &.hidden-m {
      @media #{$tablet-screen-below} {
        display: none;
      }
    }

    .systematic--item:not(.active) & {
      @media #{$tablet-screen-up} {
        display: none;
      }
    }

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &--title {
      @include font-copy-bold-white();
      margin-bottom: 1.3rem;
      @media #{$tablet-screen-up} {
        margin-bottom: 0.9rem;
      }

      &.tablet-up {
        display: none;
        @media #{$tablet-screen-up} {
          display: block;
        }
      }
      &.mobile {
        @media #{$tablet-screen-up} {
          display: none;
        }
      }
    }

    &--item {
      @include font-body-medium-white();
      &--link:hover {
        text-decoration: underline;
      }
    }

    &--button--open {
      margin-left: auto;
    }

    &--button--open,
    &--button--close {
      @media #{$tablet-screen-up} {
        display: none;
      }
      padding-left: $spacing-tiny;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &--icon {
    &--plus,
    &--minus {
      svg {
        display: inline-block;
        height: auto;
        width: 1rem;
        margin-right: 0.6rem;
        margin-bottom: -8px;
        padding-bottom: 8px;
      }

      &.small svg {
        display: inline-block;
        height: auto;
        width: 0.8rem;
        margin-right: 0.6rem;
      }
    }

    &--plus {
      .systematic--category:not(.collapsed) & {
        display: none;
      }
    }
    &--minus {
      .systematic--category.collapsed & {
        display: none;
      }
    }
  }
}