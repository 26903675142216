///////////////////////////////////////////////////////////////////////////////////
$base-border-radius: 3px !default;
$base-line-height: 1.5em !default;
$base-spacing: 1.5em !default;
$table-border: 1px solid $table-border-color;
$table-padding: .75em 1em;
//////////////////////////////////////////////////////////////////////////////////

table {
  border-collapse: separate;
  border-spacing: 0;
  margin: $small-spacing 0;
  table-layout: auto;
  width: 100%;
}

th {
  border-bottom: 1px solid shade($base-border-color, 25%);
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

td {
  border-bottom: $base-border;
  padding: $small-spacing 0;
  line-height: $base-line-height;
}

tr,
td,
th {
  vertical-align: middle;
}

tbody {
  tr:hover > td, tr:hover > th {
    background-color: $table-hover-background;
  }
  tr:first-child td {
    border-top: 2px solid $table-border-color;
  }
}

thead {
  th {
    background-color: $table-header-background;
    border-bottom: 0;
    padding: $table-padding;
  }
}

tbody {
  background-color: $table-background;

  td {
    border-bottom: 0;
    border-top: 1px solid $table-border-color;
    padding: $table-padding;
  }
}
