// Definition of local installed fonts
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 600;
$fw-black: 700;

@font-face {
  font-family: JmbPro;
  font-style: normal;
  font-weight: $fw-regular;
  src: local('JmbPro'), 
    local('JmbPro-Regular'), 
    url(/fonts/web/jmbpro-regular.woff2) format('woff2'),
    url(/fonts/web/jmbpro-regular.woff) format('woff'),
    url(/fonts/otf/JMBPro-Regular.otf) format('opentype'),
    url(/fonts/ttf/JMBPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: JmbPro;
  font-style: normal;
  font-weight: $fw-medium;
  src: local('JmbPro'), 
    local('JmbPro-Medium'), 
    url(/fonts/web/jmbpro-medium.woff2) format('woff2'),
    url(/fonts/web/jmbpro-medium.woff) format('woff'),
    url(/fonts/otf/JMBPro-Medium.otf) format('opentype'),
    url(/fonts/ttf/JMBPro-Medium.ttf) format('truetype');
}

@font-face {
  font-family: JmbPro;
  font-style: normal;
  font-weight: $fw-bold;
  src: local('JmbPro'), 
    local('JmbPro-Bold'), 
    url(/fonts/web/jmbpro-bold.woff2) format('woff2'),
    url(/fonts/web/jmbpro-bold.woff) format('woff'),
    url(/fonts/otf/JMBPro-Bold.otf) format('opentype'),
    url(/fonts/ttf/JMBPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: JmbPro;
  font-style: normal;
  font-weight: $fw-black;
  src: local('JmbPro'), 
    local('JmbPro-Black'), 
    url(/fonts/web/jmbpro-black.woff2) format('woff2'),
    url(/fonts/web/jmbpro-black.woff) format('woff'),
    url(/fonts/otf/JMBPro-Black.otf) format('opentype'),
    url(/fonts/ttf/JMBPro-Black.ttf) format('truetype');
}

@font-face {
  font-family: Headline;
  font-style: normal;
  font-weight: $fw-regular;
  src: local('JMBPro-Headline'), 
    url(/fonts/web/jmbpro-headline.woff2) format('woff2'),
    url(/fonts/web/jmbpro-headline.woff) format('woff'),
    url(/fonts/otf/JMBPro-Headline.otf) format('opentype'),
    url(/fonts/ttf/JMBPro-Headline.ttf) format('truetype');
}