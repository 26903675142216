.facets {
  &--item {
    display: block;
    width: 100%;
    margin-top: 0.9rem;

    &:first-child {
      margin-top: 0;
    }
  }

  &--group {
    border: 1px solid $jmb-gray-border;
    padding: 1.3rem;

    &--header {
      position: relative; // needed for settings box
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--title {
      @include font-copy-large-bold();
    }

    &--content {
      margin-top: 0.9rem;
    }

    &--reset {
      margin-top: 0.9rem;
      a {
        display: flex;
        align-items: baseline;
      }
      img {
        width: auto;
        height: 0.8rem;
        margin-left: 0.6rem;
      }
    }
    
    &--reset {
      @include font-link-light-color($jmb-red);
    }
  }

  &--settings {
    &--btn {
      cursor: pointer;
      img {
        width: auto;
        height: 100%;
      }
    }

    &--content {
      background-color: $jmb-blue;
      padding: to-rem(12px);

      position: absolute;
      top: 0;
      right: to-rem(30px);

      @media #{$tablet-screen-up} {
        top: to-rem(32px);
        right: -4.6rem;
      }

      > * {
        padding: 0.3rem 0.5rem;
        @media #{$tablet-screen-up} {
          padding: 0.2rem 0;
        }
      }

      button, a {
        @include font-facet-settings();
        cursor: pointer;
        display: block;
      }

      &:before {
        content: '';
        width: 0; 
        height: 0; 
        position: absolute;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 7px solid $jmb-blue;
        border-right: 0;
        top: 1px;
        right: -7px;

        @media #{$tablet-screen-up} {
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 7px solid $jmb-blue;
          border-top: 0;
          top: -7px;
          left: 50%;
          right: 50%;
        }
      }
    }
  }

  &--pagination {
    margin-top: 0.9rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    a {
      display: flex;
      align-items: baseline;
    }
    img {
      width: auto;
      height: 0.8rem;
      margin-left: 0.6rem;
    }

    &--more,
    &--less {
      @include font-link-light();
    }
    &--less a {
      align-items: center;
    }
  }

  &--list--item {
    @include font-facet-link();
    &.active > a,
    &:hover > a {
      @include font-facet-link-hover();
    }
  }
  
  &--sublist {
    margin-left: to-rem(20px);
  }

  &--check {
    @include checkbox();
    display: flex;
    align-items: center;

    &--label {
      @include font-facet-link();
      &--no-link {
        color: rgba(1,1,1,0.6);
      }
    }
  }
  &--link {
    &:hover .facets--check--label {
      @include font-facet-link-hover();
    }
  }

  &--no-facets {
    @include font-body($color: rgba(1,1,1,.6));
  }
}