.slider {
  &--overflow {
    overflow: hidden;
    display: inline-flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row wrap;
  }

  &--link {
    margin-bottom: to-rem(8px);
    margin-right: to-rem(8px);

    &.active {
      border: 2px solid $jmb-berry;
      padding: to-rem(16px);
    }
  }

  &--img {
    height: auto;
    width: 8rem;
  }
}