.form {
  &--label {
    @include font-body-medium();
  }

  &--item {
    margin-bottom: $base-spacing;

    &.form--radio {
      @include radioButton();
      margin-bottom: 0.75em;
    }
  
    &.form--checkbox {
      @include checkbox();
    }

    &.form--input,
    &.form--textarea {
      @include font-body();
      background: transparent;
      border: $base-border;
      border-radius: $base-border-radius;

      @include hover-or-focus {
        border: 1px solid $black;
      }

      &:disabled {
        background-color: shade($base-background-color, 5%);
        cursor: not-allowed;
        @include hover-or-focus {
          border: $base-border;
        }
      }
    }
  }

  &--separator {
    margin: $large-spacing 0;
    border: 1px solid $jmb-gray-divider;
  }

  &--error-message {
    color: $jmb-red;
  }

  &--fieldset {
    border: $base-border;
    padding: 2rem 1rem 1.5rem;
    margin: 1.5rem 0;
    position: relative;

    legend {
      display: block;
      background-color: $white;
      color: $base-font-color;
      position: absolute;
      top: -18px;
      left: 1rem;
      padding: 0.5rem 1rem;
    }
  }
}
